export default {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "dev",
    client: {
      javascript: {
        code_version: "4.0.0",
      },
    },
  },
}
