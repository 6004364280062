import type MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import type { Map } from "mapbox-gl"
import type { Component } from "vue"

export enum Variation {
  ALL = "all",
  SENIOR = "senior",
  JUNIOR = "junior",
}

export interface Tab {
  value: string
  label: string
}

export interface TabWithComponent extends Tab {
  component: Component
}

export interface Notification {
  text: string
  title: string
  type: string
}

export interface Score {
  informationsAccess: number
  numericAccess: number
  numericSkills: number
  administrativeSkills: number
  total: number
}

export interface MapInstance {
  map: Map | null
  geocoder: MapboxGeocoder | null
  extraGeocoders: Record<string, MapboxGeocoder>
}

export interface CustomGeometry {
  code: string
  color: string
  coordinates: Array<any>
  name: string
  type: Division
}

interface ScoreWithColor {
  score: number | undefined
  color: string
}
export interface ICityScoreWithColor {
  [key: string]: ScoreWithColor
}
interface InfoData {
  name: string
  division: Division
  center: [number, number]

  code: string
  population: number
  area: number
  population_density: number
  number_of_residential_or_mixed_plugs: number

  eth_code?: string
}
export interface ICityData {
  info: InfoData
  score: IFNVariables
}

export interface ObjectByKey<T> {
  [key: string]: T
}

export type IFNVariables = {
  [key in IndicatorId]?: number
} & {
  total?: number
}

export enum Division {
  eth = "eth",
  city = "city",
}

export enum CategoryId {
  NUMERICAL_FRAGILITY_INDEX = "numerical_fragility_index",
  TERRITORY_CONTEXT = "territory_context",
}

export const divisionBorderWidth = {
  [Division.eth]: 4,
  [Division.city]: 3.5,
}

export enum TerritoryCategoryId {
  TERRITORY_INFORMATIONS = "territory_informations",
}
export enum IndicatorId {
  NO_THD_COVERAGE_RATE = "no_thd_coverage_rate",
  NO_4G_COVERAGE_RATE = "no_4g_coverage_rate",
  OLDER_65_RATE = "older_65_rate",
  NSCOL15P_RATE = "nscol15p_rate",
  POVERTY_RATE = "poverty_rate",
  FAMMONO_RATE = "fammono_rate",
  MENSEUL_RATE = "menseul_rate",
  OBSTACLE_TO_MOBILITY_RATE = "obstacle_to_mobility_rate",
  UNEMPLOYMENT_RATE = "unemployment_rate",
  SOCIAL_ASSISTANCE_CLAIM_RATE = "social_assistance_claim_rate",
  PERSONAL_AUTONOMY_ALLOWANCE_BENEFICIARY_RATE = "personal_autonomy_allowance_beneficiary_rate",
  PUBLIC_SERVICE_DISTANCE_RATE = "public_service_distance_rate",
  DIGITAL_ACCESS_POINTS_DISTANCE_RATE = "digital_access_points_distance_rate",
}
export enum TerritoryIndicatorId {
  POPULATION = "population",
  AREA = "area",
  POPULATION_DENSITY = "population_density",
  NUMBER_OF_RESIDENTIAL_OR_MIXED_PLUGS = "number_of_residential_or_mixed_plugs",
}
export interface Category {
  name: string
  description: string
  indicators: IndicatorId[]
}

export interface Indicator {
  name: string
  description: string
  source?: string
  variations: Variation[]
  equivalents?: { [key in Variation]?: IndicatorId }
}

export type IndicatorIdsByVariation = {
  [key in Variation]: IndicatorId[]
}

export type IndicatorIdsByVariationByCategoryId = {
  [key in CategoryId]: IndicatorIdsByVariation
}

export enum QueryParam {
  COMPARATIVE_TABLE_TITLE = "comparative_table_title",
  COMPARATIVE_VALUE_DISPLAYED = "comparative_value_displayed",
  DIVISION = "division",
  VARIATION = "variation",
  INDICATORS = "indicators",
  READ_MORE_CATEGORY_ID = "read_more_category_id",
  SHOW_DROM = "show_drom",
  OPEN_SHARING = "open_sharing",
  ZOOM = "zoom",
  CENTER = "center",
  DIVISION_AUTO = "division_auto",
  SELECTED_TERRITORIES = "selected_territories",
  SELECTED_TERRITORIES_ORDER_BY = "selected_territories_order_by",
}

export interface DROMComponent {
  src: any // TODO
  title: string
  center: [number, number]
}

export enum DROMId {
  GUADELOUPE = "971",
  GUYANE = "973",
  MARTINIQUE = "972",
  MAYOTTE = "976",
  NOUVELLE_CALEDONIE = "988",
  POLYNESIE_FRANCAISE = "987",
  REUNION = "974",
  SAINT_BARTHELEMY = "977",
  SAINT_MARTIN = "978",
  SAINT_PIERRE_ET_MIQUELON = "975",
  WALLIS_ET_FUTUNA = "986",
}

export interface CodeDivision {
  code: string
  division: Division
}
export interface CodeDivisionWithScore extends CodeDivision {
  score: ICityData
}

export enum OrderByValue {
  ALPHABETICALLY = "alphabetically",
  UNALPHABETICALLY = "-alphabetically",
  SCORE = "score",
  INVERSE_SCORE = "-score",
}

export interface OrderByField {
  shortLabel: string
  label: string
  value: OrderByValue
  asc: boolean
}
export interface TerritoryCardButton {
  label: string
  action: () => any

  class?: string
  show?: () => boolean
}
export interface TerritoryCardOptions {
  class?: string
  buttons: TerritoryCardButton[]
}
export enum COMPARATIVE_VALUE_TO_DISPLAY {
  SCORE = "score",
  INFORMATION = "informations",
}

export interface TerritoryCategory {
  name: string
  description: string
  indicators: TerritoryIndicatorId[]
}

export interface TerritoryIndicator {
  description?: string
  name: string
  shortName?: string
  unit?: string
}

export interface TerritoryIndicatorLine extends TerritoryIndicator {
  value: any
}

export type SearchTerritory = {
  name: string
  code?: string
  division?: Division
  center?: [number, number]
}
export type FoundTerritory = Required<SearchTerritory>

export type Timeout = ReturnType<typeof setTimeout>
