import App from "./App.vue"
import router from "./router"
import "@/css/app.sass"
import { createPinia } from "pinia"
import { createApp } from "vue"
import RollbarPlugin from "@/plugins/rollbar"

const app = createApp(App)
// enable component init, compile, render and patch performance tracing in the browser devtool performance/timeline panel.
// https://vuejs.org/api/application.html#app-config-performance
app.config.performance = true
app.use(createPinia())
app.use(router)
app.use(RollbarPlugin)

app.mount("#app")
