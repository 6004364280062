import type { Notification } from "@/types"
import { Division, Variation } from "@/types"
import type { LngLatBoundsLike } from "mapbox-gl"

export const DEFAULT_NOTIFICATION: Notification = {
  text: "",
  title: "",
  type: "",
}
export const APP_NAME = "Cartographie"

export const DEFAULT_CENTER: [number, number] = [165.552, -21.27]

const DIFFERENCE_BETWEEN_CENTER = 12
export const MAX_BOUNDS: LngLatBoundsLike = [
  [
    DEFAULT_CENTER[0] - DIFFERENCE_BETWEEN_CENTER,
    DEFAULT_CENTER[1] - DIFFERENCE_BETWEEN_CENTER,
  ], // Southwest coordinates
  [
    DEFAULT_CENTER[0] + DIFFERENCE_BETWEEN_CENTER,
    DEFAULT_CENTER[1] + DIFFERENCE_BETWEEN_CENTER,
  ], // Northeast coordinates
]

export const MIN_ZOOM_LEVEL = 5
export const DEFAULT_ZOOM_LEVEL = 7

export const MIN_ZOOM_LEVELS = {
  [Division.eth]: MIN_ZOOM_LEVEL,
  [Division.city]: MIN_ZOOM_LEVEL,
}

const MAX_ZOOM = 14
export const MAX_ZOOM_LEVELS = {
  [Division.eth]: MAX_ZOOM,
  [Division.city]: MAX_ZOOM,
}

export const FRDivision = {
  [Division.city]: "Commune",
  [Division.eth]: "ETH",
}
export const DIVISION_ZOOM_LEVELS = {
  [Division.city]: 8,
}
export const MAP_ZOOM_LEVELS = {
  min: MIN_ZOOM_LEVEL,
  max: MAX_ZOOM,
}

export const BASE_API_URL = import.meta.env.VITE_BASE_API_URL || "/api"
export const DOCUMENTATION_URL = "https://infos.fragilite-numerique.fr"

export const FULL_BASE_API_URL =
  import.meta.env.VITE_BASE_API_URL || `${window.location.origin}/api`

export const VARIATION_TEXT = {
  [Variation.ALL]: "Tout âge",
  [Variation.SENIOR]: "Senior",
  [Variation.JUNIOR]: "Jeune",
}

export const FETCHING_DEBOUNCE = 300
export const LIST_URL_DIVIDER = ","
export const CODE_DIVISION_URL_DIVIDER = "-"

export const DEFAULT_COMPARATIVE_TABLE_TITLE =
  "Titre de votre tableau d’analyse des territoires sélectionnés"

export const DEFAULT_TERRITORY_CARD_CONTAINER = "territory-card"

export const IFN_FILL_LAYER_BASE_ID = "ifn-fill-"
export const IFN_BORDER_LAYER_BASE_ID = "ifn-border-"
export const IFN_SOURCE_BASE_ID = "ifn-geometries-"
