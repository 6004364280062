import Rollbar from "rollbar"
import config from "../rollbar.config"

export default {
  install(app: any) {
    if (!import.meta.env.VITE_ROLLBAR_TOKEN) {
      return
    }
    const rollbar = new Rollbar(config)

    app.config.errorHandler = (error: any, vm: any, info: any) => {
      rollbar.error(error, { vueComponent: vm, info })
      if (app.config.devtools) {
        console.error(error)
      }
    }
    app.provide("rollbar", rollbar)
  },
}
