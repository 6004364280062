import { BASE_API_URL } from "@/utils/constants"
import axios from "axios"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useAppStore = defineStore("app", () => {
  const isLoading = ref<boolean>(false)
  const externalLinks = ref<
    {
      name: string
      url: string
    }[]
  >([])
  const showLoader = () => {
    isLoading.value = true
  }
  const hideLoader = () => {
    isLoading.value = false
  }

  const getExternalLinks = async () => {
    const response = await axios.get(`${BASE_API_URL}/external-links/`)
    if (response.status === 200) {
      externalLinks.value = response.data
    }
    return response
  }
  return {
    externalLinks,
    getExternalLinks: getExternalLinks,
    hideLoader,
    isLoading,
    showLoader,
  }
})
